import Head from "next/head";
import './_global_style.css';
import { useRouter } from "next/router";
import React, { useEffect } from "react";
import gtag, { install } from 'ga-gtag';
import { GA_ANALYTICS_MEASUREMENT_ID } from "@util/gtag";
import { QueryClient, QueryClientProvider } from "react-query";
import { PostHogProvider } from "posthog-js/react";
import posthog from "posthog-js";
import useHubspotTracking from "../hooks/useHubspotTracking";
import { cleanURLFromEmail } from "@util/facebook.util";

const queryClient = new QueryClient()

// Check that PostHog is client-side (used to handle Next.js SSR)
if (typeof window !== 'undefined') {
  posthog.init(process.env["NEXT_PUBLIC_POSTHOG_TOKEN"] ?? 'phc_FoguRdqntJq7sE6omUMTvMIuOqDhw5Z0qzciSJfetar', {
    api_host: 'https://analytics.dieselgate.com',
    autocapture: false,
    loaded: (posthog) => {
      if (process.env.NODE_ENV === 'development') posthog.opt_out_capturing()
    },
  })
}

const MyApp = ({Component, pageProps}) => {
  const router = useRouter();
  const {setPathPageView} = useHubspotTracking();

  useEffect(() => {
    import('@bettercart/react-facebook-pixel')
      .then((x) => x.default)
      .then((ReactPixel) => {
        ReactPixel.init('1016669972300213');
      })
  }, [])

  useEffect(() => {
    const handleRouteChange = (url: URL) => {
      setPathPageView(url.pathname);

      gtag('event', 'page_view', {
        page_path: url,
        send_to: GA_ANALYTICS_MEASUREMENT_ID
      });

      import('@bettercart/react-facebook-pixel')
        .then((x) => x.default)
        .then((ReactPixel) => {
          ReactPixel.track('PageView', {url: cleanURLFromEmail()});
        })
    };

    if (typeof window !== "undefined") {
      install(GA_ANALYTICS_MEASUREMENT_ID, {'send_page_view': false});
    }

    router.events.on("routeChangeComplete", handleRouteChange);
    return () => {
      router.events.off("routeChangeComplete", handleRouteChange);
    };
  }, [ router.events ]);


  //posthog
  useEffect(() => {
    // Track page views
    const handleRouteChange = () => posthog?.capture('$pageview')
    router.events.on('routeChangeComplete', handleRouteChange)

    return () => {
      router.events.off('routeChangeComplete', handleRouteChange)
    }
  }, [])

  React.useEffect(() => {
    document.addEventListener("keydown", function (event: any) {
      if (event.keyCode === 13 && event.target.nodeName === "INPUT") {
        const form = event.target.form;
        const index = Array.prototype?.indexOf.call(form, event.target) ?? 0;

        if (form.elements[index + 1]?.tagName === 'INPUT') {
          form.elements[index + 1].focus();
          event.preventDefault();
        }
      }
    });
  }, []);

  return (
    <PostHogProvider
      client={ posthog }
    >
      <QueryClientProvider client={ queryClient }>
        <Head>
          <title>Dieselgate</title>
          <meta name="viewport" content="width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no"/>
          <meta name="theme-color" content="#E84E1A"/>

          <script type="text/javascript" id="hs-script-loader" async defer
                  src="//js.hs-scripts.com/8525212.js"></script>
        </Head>

        <Component { ...pageProps } />
      </QueryClientProvider>
    </PostHogProvider>
  )
}

export default MyApp
